export default {
  trustDataBegin(state) {
    state.isLoading = true;
    state.error = null;
  },

  trustDataErr(state, err) {
    setTimeout(() => {
      state.isLoading = false;
    }, 500);
    state.error = err;
  },

  trustProductsDataSuccess(state, data) {
    state.isLoading = false;
    state.products = data;
    // state.meta = data.meta.pagination;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },
};
