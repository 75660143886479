import PageRoutes from './PageRoutes';

const routes = [
  {
    path: '',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '@/view/start/StartPage.vue'),
    children: [
      {
        path: ':actionType/:accId?',
        component: () => import(/* webpackChunkName: "actionAcc" */ '@/components/modals/ModalCash.vue'),
        props: true,
      },
    ],
  },
  ...PageRoutes,
];

export default routes;
