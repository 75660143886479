import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  data: [],
  meta: {},
  dealsData: [],
  dealsMeta: {},
  graphData: [],
  accountData: {},
  leverages: null,
  loading: null,
  createLoading: false,
  fileLoading: null,
  error: null,
});
const actions = {
  async getTradingAccounts({ commit }, { page, per_page, type }) {
    try {
      commit('tradingAccountsReadBegin', { getTrade: per_page < 4 });
      let query = await axios.get(
        `${apiLinks[`trading_${type}`]}?page=${page}&per_page=${per_page ? per_page : 3}${
          per_page % 3 === 0 ? '&sort_by=created_at&order=desc' : ''
        }`,
      );

      commit('tradingAccountsReadSuccess', query.data);
    } catch (err) {
      commit('axiosTradingAccountsReadErr', err);
    }
  },

  async getTradingAccount({ commit }, { id }) {
    try {
      commit('tradingAccountsReadBegin');
      const query = await axios.get(`${apiLinks.trading_accounts}${id}`);
      commit('tradingAccountReadSuccess', query.data);
    } catch (err) {
      commit('axiosTradingAccountsReadErr', err);
    }
  },

  async getLeverages({ commit }) {
    try {
      commit('tradingAccountsReadBegin');
      const query = await axios.get(`${apiLinks.trading_leverages}`);
      commit('leveragesReadSuccess', query.data);
    } catch (err) {
      commit('axiosTradingAccountsReadErr', err);
    }
  },

  async createTradingAccount({ commit }, { type, name, amount, leverage }) {
    try {
      commit('tradingAccountsReadBegin', true);
      const query = await axios.post(
        `${
          apiLinks[`trading_${type}`]
        }?trade_account[name]=${name}&trade_account[amount]=${amount}&trade_account[leverage]=${leverage}`,
      );

      commit('tradingAccountCreateSuccess', query.data);
    } catch (err) {
      // console.log(err);
      commit('axiosTradingAccountsReadErr', err);
    }
  },

  async topUpDemoAccount({ commit }, { id, amount }) {
    try {
      commit('tradingAccountsReadBegin');
      await axios.post(`${apiLinks.trading_demo}${id}/${apiLinks.deposit_requests}`, { amount });
      commit('tradingAccountTopUpSuccess');
    } catch (err) {
      commit('axiosTradingAccountsReadErr', err);
    }
  },
  clearTradingErrors({ commit }) {
    commit('clearTradingErrors');
  },
  async getDeals({ commit }, { accId, pageNum, pageSize, sortableLink }) {
    try {
      commit('tradingAccountsReadBegin');

      const query = await axios.get(
        apiLinks.trading + `${accId}/` + apiLinks.deals + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('axiosDealsGetSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },
  async getGraphTradingStats({ commit }, { id }) {
    try {
      commit('tradingAccountsReadBegin');
      const query = await axios.get(`${apiLinks.trading_accounts}${id}/${apiLinks.graph}?group_by=hour`);
      await commit('axiosAccountGraphStats', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },

  async closeDemoAccount({ commit }, { id }) {
    try {
      commit('tradingAccountsReadBegin');
      const query = await axios.patch(`${apiLinks.trading_demo}${id}/${apiLinks.close}`);
      commit('closeAccount', query.data.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async checkContestMember({ commit }, { id }) {
    try {
      commit('tradingAccountsReadBegin');
      const query = await axios.post(`${apiLinks.member}${id}/${apiLinks.check}`);
      commit('checkContestMember', query.data.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  updateRequestDeposit({ commit }, data) {
    commit('updateRequestDeposit', data);
  },

  updateMt5AccountData({ commit }, data) {
    commit('updateMt5AccountData', data);
  },
  updateContestMember() {},
};

export default {
  namespaced: false,
  actions,
  mutations,
  state,
};
