import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  data: null,
  userData: null,
  subscription: null,
  isLoading: false,
  changeSuccess: null,
  error: null,
  userSettings: null,
  twoFac: null,
  locale: 'en',
  twoFactorKey: null,
  mailAcc: null,
  wallets: { data: [], meta: {} },
  creatingText: null,
  twoCode: null,
});

const actions = {
  async axiosProfileGetData({ commit }) {
    try {
      commit('profilePersonalDataBegin');

      const query = await axios.get(apiLinks.profile);

      commit('profilePersonalDataSuccess', query.data);
    } catch (err) {
      await commit('profilePersonalDataErr', err);
    }
  },

  async axiosChangeProfileData({ commit }, changebleEntity) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.patch(apiLinks.profile + apiLinks.personal_user_data, changebleEntity);
      commit('axiosDataChange', query);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async axiosChangeEmail({ commit }, changebleEntity) {
    try {
      commit('profilePersonalDataBegin');

      const query = await axios.patch(apiLinks.profile + apiLinks.links_email, changebleEntity);

      commit('axiosDataContactsChange', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async axiosChangePhone({ commit }, changebleEntity) {
    try {
      commit('profilePersonalDataBegin');

      const query = await axios.patch(apiLinks.profile + apiLinks.links_phone, changebleEntity);
      commit('axiosDataContactsChange', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async axiosChangePassword({ commit }, changebleEntity) {
    try {
      await commit('profilePersonalDataBegin');
      await axios.patch(apiLinks.profile + apiLinks.links_password, changebleEntity);
      commit('profileChangePersonalDataSuccess');
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async axiosGetSettings({ commit }) {
    try {
      const query = await axios.get(apiLinks.profile + apiLinks.settings);

      commit('axiosGetSuccess', query.data);
    } catch (err) {
      await commit('axiosGetErr', err);
    }
  },

  async axiosChangeSettings({ commit }, { locale, time_zone }) {
    try {
      commit('profilePersonalDataBegin');

      const query = await axios.post(apiLinks.profile + apiLinks.settings, { locale, time_zone });

      commit('axiosChangeSuccess', query.statusText);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async getCodeTwoFactory({ commit }) {
    try {
      const query = await axios.get(apiLinks.profile + apiLinks.two_fac);
      commit('getCodeTwoFactory', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async getCodeTwoFactoryAgain({ commit }) {
    try {
      const query = await axios.get(apiLinks.profile + apiLinks.two_fac + apiLinks.show);
      commit('getCodeTwoFactory', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async enableTwoFactory({ commit }, sendableEntinity) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.post(apiLinks.profile + apiLinks.two_fac, sendableEntinity);

      commit('axiosEnableTwoFac', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async disableTwoFactory({ commit }, sendableEntinity) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.delete(apiLinks.profile + apiLinks.two_fac, { data: sendableEntinity });

      commit('axiosDisableTwoFac', query.statusText);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async mailAccept({ commit }, confirmation_token) {
    try {
      const query = await axios.get(apiLinks.email + confirmation_token);

      commit('mailAccepted', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async walletsGetData({ commit }, { pageNum, pageSize, sortableLink }) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.get(apiLinks.wallets + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`);

      commit('profileWalletsDataSuccess', query.data);
    } catch (err) {
      await commit('profilePersonalDataErr', err);
    }
  },

  async getAllWallets({ commit }) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.get(apiLinks.wallets + `?per_page=100&sort_by=id&oreder=desc`);

      commit('getAllWalletsDataSuccess', query.data);
    } catch (err) {
      await commit('profilePersonalDataErr', err);
    }
  },

  updateUserWallet({ commit }, data) {
    commit('updateWalletsSuccess', data);
  },

  async createNewWallet({ commit }, changebleEntity) {
    try {
      commit('profilePersonalDataBegin');
      const query = await axios.post(apiLinks.wallets, changebleEntity);
      commit('axiosCreateWalletSuccess', query);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
  async deleteWallet({ commit }, { id, idx }) {
    try {
      await axios.delete(apiLinks.wallets + id);

      commit('axiosDeltetWalletSuccess', idx);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },

  async mailReconfirmation({ commit }, user) {
    try {
      const query = await axios.post(apiLinks.email, {
        user: {
          email: user.email,
          locale: user.locale,
        },
      });

      commit('mailAccepted', query.data);
    } catch (err) {
      await commit('axiosChangeErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
