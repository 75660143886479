import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  isLoading: false,
  error: null,
  data: [],
  meta: {},
});

const actions = {
  async transactionsGetData({ commit }, { pageNum, pageSize, sortableLink }) {
    try {
      commit('transactionsDataBegin');
      const query = await axios.get(apiLinks.all_transactions + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`);

      commit('transactionsDataSuccess', query.data);
    } catch (err) {
      await commit('transactionsDataErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
