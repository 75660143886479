//import store from '../../../store';

export default {
  accountsReadBegin(state, data) {
    if (data) {
      state.public = { data: [], meta: {} };
      state.invest = { data: [], meta: {}, total: {} };
    }
    state.loading = true;
    state.error = null;
  },

  tableReadBegin(state) {
    state.loadingTable = true;
    state.error = null;
  },

  publicGetSuccess(state, { data, type }) {
    if (type.includes('publish')) {
      state.public.data = [...state.public.data, ...data.data];
      state.public.meta = data.meta.pagination;
    } else if (type.includes('invest')) {
      state.invest.data = [...state.invest.data, ...data.data];
      state.invest.meta = data.meta.pagination;
      state.invest.total = data.meta.total;
    }

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  getProfitSuccess(state, data) {
    state.profit.data = data;
    //state.profit.meta = data.meta;

    setTimeout(() => {
      state.loadingTable = false;
    }, 100);
  },

  axiosError(state, err) {
    setTimeout(() => {
      state.loading = false;
      state.createLoading = false;
    }, 500);
    if (err.response?.status != 500) {
      state.error = err.response?.data;
    } else {
      state.error = 500;
    }
  },

  publicAccountReadSuccess(state, data) {
    state.account = data.data;
    state.loading = false;
    state.error = null;
  },

  clearPublicErrors(state) {
    state.error = null;
    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  axiosAccountGraphStats(state, data) {
    state.graphData = data;
    state.error = null;

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  updateTradingAccount(state) {
    //const account_id = data.id;
    //const id = data.id;
    //store.dispatch('updateMt5AccountData', { ...data.attributes, account_id, id });

    //state.public.data = state.public.data.map((acc) => {
    //  if (+acc.id === +id) {
    //    Object.assign(...acc.attributes, ...data.attributes);
    //  }
    //  return acc;
    //});

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  transferSendSuccess(state) {
    //state.invest.data = state.invest.data.map((acc) => {
    //  if (+acc.id === +data.attributes.account_from_id) {
    //    acc.attributes.amount -= +data.attributes.amount;
    //  }
    //  if (+acc.id === +data.attributes.account_to_id) {
    //    acc.attributes.amount = +acc.attributes.amount + +data.attributes.amount;
    //  }
    //  return acc;
    //});

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  unpublishAccount(state, id) {
    state.public.data = state.public.data.filter((acc) => acc.id !== id);
  },

  closeAccount(state, id) {
    state.invest.data = state.invest.data.map((acc) => {
      if (+acc.id === +id) {
        acc.attributes.status = 'closing';
      }
      return acc;
    });

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  createInvestorAccountSuccess(state) {
    setTimeout(() => {
      state.loading = false;
    }, 100);
  },
};
