const getItem = (key) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return localStorage.setItem(key, stringify);
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

const setCookie = ({ name, value }) => {
  const hostname = document.location.hostname.split('.').slice(-2).join('.');
  document.cookie = `${name}=${value};domain=${hostname};path=/;max-age=31536000;`;
};

const getCookie = (name) => {
  let matches = document.cookie.match(
    /*eslint-disable-next-line*/
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export { getItem, setItem, removeItem, setCookie, getCookie };
