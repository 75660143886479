import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';
import router from '@/routes/protectedRoute';

const state = () => ({
  data: null,
  contestData: null,
  membersData: null,
  membersMeta: null,
  url: null,
  pagination: null,
  fileLoading: false,
  error: null,
  loadingPerticipate: null,
});

const actions = {
  async axiosContestsGetData({ commit }) {
    try {
      commit('contestsReadBegin');
      const query = await axios.get(apiLinks.contests);
      commit('contestsReadSuccess', query.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async axiosGetContest({ commit }, { id }) {
    try {
      commit('contestsReadBegin');
      const query = await axios.get(apiLinks.contests + id);
      commit('getOneContestSuccess', query.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async axiosMembersGetData({ commit }, { id }) {
    try {
      commit('contestsReadBegin');
      const query = await axios.get(apiLinks.contests + id + '/' + apiLinks.members);
      commit('membersReadSuccess', query.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async perticipiateContest({ commit }, { id }) {
    try {
      commit('perticipateReadBegin');
      const query = await axios.post(apiLinks.contests + id + '/' + apiLinks.members);
      router.push(`/trading-account/${query.data.data.id}/page/1`);
      commit('perticipateSuccess');
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },
  resetContestErrors({ commit }) {
    commit('resetContestErrors');
  },

  updateUser({ commit }, data) {
    commit('updateUser', data);
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  state,
};
