/* eslint-disable */

import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';

import { auth } from './modules/authentication/axios/actionCreator';
import notifications from './modules/notifications/axios/actionCreator';
import profile from './modules/profile/axios/actionCreator';
import security from './modules/security/axios/actionCreator';

import accounts from './modules/accounts/axios/actionCreator';
import tradingAccounts from './modules/trading-accounts/axios/actionCreator';
import publicAccounts from './modules/public-accounts/axios/actionCreator';
import partners from './modules/partners/axios/actionCreator';

import contests from './modules/contests/axios/actionCreator';
import lootBoxes from './modules/loot-boxes/axios/actionCreator';
import shop from './modules/shop/axios/actionCreator';
import transactions from './modules/transactions/axios/actionCreator';

import trust from './modules/trust/axios/actionCreator';

export default createStore({
  modules: {
    themeLayout,

    auth,
    notifications,
    profile,
    security,

    accounts,
    tradingAccounts,
    publicAccounts,
    partners,

    contests,
    lootBoxes,
    shop,
    transactions,

    trust,
  },
  getters: {
    token: (state) => auth.state.user.token,
    refresh: (state) => auth.state.user.refresh,
  },
});
