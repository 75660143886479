import AuthService from '@/config/services/auth.service';
import { getItem } from '@/utility/localStorageControl';
import finger from '@/config/helpers/fingerPrint';

let fingerPrint;
finger().then((e) => (fingerPrint = e));
const user = getItem('user');
const initState = user
  ? { loggedIn: true, user, message: null, loading: false, isExpireToken: false }
  : { loggedIn: false, user: null, message: null, loading: false, isExpireToken: false };
export const auth = {
  namespaced: true,
  state: initState,
  actions: {
    login({ commit }, user) {
      user.fingerprint = fingerPrint;
      commit('beforeSign');
      return AuthService.login(user).then(
        (user) => {
          if (!user.success) {
            commit('loginSuccess', user);
            commit('expireTokenFalse');
          }
          commit('beforeAuth');
          return Promise.resolve(user);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        },
      );
    },

    register({ commit }, user) {
      commit('beforeSign');
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerFailure');
          return Promise.reject(error);
        },
      );
    },

    refresh({ state, commit }) {
      try {
        return AuthService.refresh(state.user.refresh, fingerPrint).then(
          (user) => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          (error) => {
            commit('loginFailure');
            return Promise.reject(error);
          },
        );
      } catch (error) {
        return Promise.reject(error);
      }
    },

    logout({ commit }, expireToken) {
      if (expireToken) {
        setTimeout(() => commit('expireTokenTrue'), 2000);
      }
      return AuthService.logout().then(commit('logout'));
    },

    AuthSec({ commit }, user) {
      commit('beforeSign');
      return AuthService.authSecond(user).then(
        (user) => {
          if (!user.success) {
            commit('AuthSecSuccess', user);
            commit('expireTokenFalse');

            return Promise.resolve(user);
          }
        },
        (error) => {
          commit('AuthSecFailure');
          return Promise.reject(error);
        },
      );
    },

    forgotPassword({ commit }, email) {
      commit('beforeSign');
      return AuthService.forgotPassword(email).then(
        (response) => {
          commit('forgotPasswordApplicationSuccess', response);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('forgotPasswordApplicationError', error);
          return Promise.reject(error);
        },
      );
    },

    resetPassword({ commit }, data) {
      commit('beforeSign');
      return AuthService.resetPassword(data).then(
        (response) => {
          commit('forgotPasswordApplicationSuccess', response);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('forgotPasswordApplicationError', error);
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    beforeSign(state) {
      state.loading = true;
    },

    loginSuccess(state, user) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = true;
      state.user = user;
    },
    beforeAuth(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
    },
    AuthSecSuccess(state, user) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = true;
      state.user = user;
    },

    AuthSecFailure(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = false;
      state.user = null;
    },

    loginFailure(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = false;
      state.user = null;
    },

    registerSuccess(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = false;
    },

    registerFailure(state) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.loggedIn = false;
    },

    forgotPasswordApplicationSuccess(state, data) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.message = data.data;
    },

    forgotPasswordApplicationError(state, data) {
      setTimeout(() => {
        state.loading = false;
      }, 500);
      state.message = data.data;
    },

    expireTokenTrue(state) {
      state.isExpireToken = true;
    },

    expireTokenFalse(state) {
      state.isExpireToken = false;
    },
  },
};
