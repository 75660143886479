export default {
  axiosReadSuccess(state, { data, idx }) {
    if (idx || idx == 0) {
      state.tosterNotifications[idx] = data.data;
      state.newNotifications--;
    }
  },

  axiosReadErr(state, err) {
    state.error = err;
  },

  updateNotifications(state, data) {
    const updateData = {
      id: data.id,
      attributes: {
        created_at: Date.now(),
        read_at: null,
        message: data.content,
      },
    };
    if (state.notifications) {
      state.notifications.unshift(updateData);
    }
    state.tosterNotifications.unshift(updateData);
    state.newNotifications++;
  },

  getNotificationsSuccess(state, data) {
    state.error = false;
    state.notifications = data.data;
    state.pagination = data.meta;
  },

  getNotificationSuccess(state, data) {
    state.error = false;
    state.notification = data;
  },

  getTosterNotificationsSuccess(state, data) {
    state.error = false;
    state.tosterNotifications = data.data;
    state.newNotifications = data.meta.unread_count;
  },

  changeNewNotifications(state) {
    state.newNotifications++;
  },

  axiosSendErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },
};
