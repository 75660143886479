export default [
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "Accounts" */ '@/view/accounts/Accounts.vue'),
    children: [
      {
        path: ':actionType/:accId',
        component: () => import(/* webpackChunkName: "actionAccount" */ '@/components/modals/ModalCash.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/account/:accId/page/:pageNum',
    name: 'account',
    component: () => import(/* webpackChunkName: "accountInfo" */ '@/view/accounts/AccountsInfoPage.vue'),
    children: [
      {
        path: ':actionType',
        component: () => import(/* webpackChunkName: "actionAc" */ '@/components/modals/ModalCash.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/trading/:type/',
    name: 'trading',
    component: () => import(/* webpackChunkName: "trading" */ '@/view/public-accounts/AccountCards.vue'),
  },

  {
    path: '/trading/:type/:id',
    name: 'trading-acc',
    component: () => import(/* webpackChunkName: "trading-acc" */ '@/view/public-accounts/AccountCardsInfo.vue'),
  },

  {
    path: '/trading-accounts/:type/',
    name: 'trading-accounts',
    component: () => import(/* webpackChunkName: "trading-accounts" */ '@/view/trading/TradingAccounts.vue'),
    children: [
      {
        path: ':actionType/:accId',
        component: () => import(/* webpackChunkName: "actionAccount" */ '@/components/modals/ModalCash.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/trading-account/:accId/page/:pageNum',
    name: 'trading-account',
    component: () => import(/* webpackChunkName: "trading-account" */ '@/view/trading/TradingAccountsInfoPage.vue'),
    children: [
      {
        path: ':actionType',
        component: () => import(/* webpackChunkName: "actionAc" */ '@/components/modals/ModalCash.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/terminal/',
    name: 'mt5-terminal',
    component: () => import(/* webpackChunkName: "terminal" */ '@/view/trading/WebTerminal.vue'),
  },
  {
    path: '/wallets/page/:pageNum?',
    name: 'set-wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '@/view/wallets/WalletsPage.vue'),
  },

  {
    path: '/solar-feed',
    name: 'solar-feed',
    component: () => import(/* webpackChunkName: "SolarFeed" */ '@/view/pages/SolarFeed.vue'),
    props: true,
    //children: [
    //  {
    //    path: 'faq',
    //    name: 'faq',
    //    //component: () => import(/* webpackChunkName: "faq" */ '@/view/pages/support/overview/Faq.vue'),
    //    component: () => import(/* webpackChunkName: "faq" */ '@/view/pages/Maintenance.vue'),
    //  },
    //  {
    //    path: 'knowledge-base',
    //    name: 'knowledgeBase',
    //    //component: () =>
    //    //import(/* webpackChunkName: "KnowledgeBase" */ '@/view/pages/support/overview/KnowledgeBase.vue'),
    //    component: () => import(/* webpackChunkName: "KnowledgeBase" */ '@/view/pages/Maintenance.vue'),
    //  },
    //  {
    //    path: 'knowledge-base/post/:postId?',
    //    name: 'post',
    //    component: () => import(/* webpackChunkName: "post" */ '@/view/pages/support/overview/OnePost.vue'),
    //  },
    //],
  },

  {
    path: '/partners/',
    name: 'partners',
    component: () => import(/* webpackChunkName: "partners" */ '@/view/partners/Partners.vue'),
    props: true,
  },
  {
    path: '/contests/',
    name: 'contests',
    component: () => import(/* webpackChunkName: "contests" */ '@/view/contests/ContestsPage.vue'),
    props: true,
  },
  {
    path: '/contest/:id',
    name: 'contest',
    component: () => import(/* webpackChunkName: "contest" */ '@/view/contests/ContestsInfo.vue'),
    props: true,
  },
  {
    path: '/partner-tree/',
    name: 'partner-tree',
    component: () => import(/* webpackChunkName: "partner-tree" */ '@/view/partners/PartnersTree.vue'),
    props: true,
  },
  {
    path: '/notifications/:pageNum',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/view/notifications/NotificationsCRUD.vue'),
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "404" */ '@/view/pages/404.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'),
    children: [
      {
        path: 'view',
        name: 'overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/view/pages/settings/overview/Profile.vue'),
      },
      {
        path: 'settings/password',
        name: 'set-password',
        component: () => import(/* webpackChunkName: "Password" */ '@/view/pages/settings/overview/Password.vue'),
      },
      {
        path: 'settings/security/page/:pageNum',
        name: 'security',
        component: () => import(/* webpackChunkName: "security" */ '@/view/pages/settings/overview/Security.vue'),
        props: true,
      },
      {
        path: 'settings/global',
        name: 'set-global',
        component: () => import(/* webpackChunkName: "global" */ '@/view/pages/settings/overview/Global.vue'),
      },
      {
        path: 'settings/two-factor',
        name: 'two-factor',
        component: () => import(/* webpackChunkName: "two-factor" */ '@/view/pages/settings/overview/TwoFactor.vue'),
      },
      {
        path: 'settings/subscription',
        name: 'subscription',
        component: () => import(/* webpackChunkName: "two-factor" */ '@/view/pages/settings/overview/Subscription.vue'),
      },
    ],
  },
  {
    path: '/transactions/:pageNum?',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "contests" */ '@/view/transactions/TransactionsPage.vue'),
    props: true,
  },
  {
    path: '/trust/products/',
    name: 'trust-products',
    component: () => import(/* webpackChunkName: "contests" */ '@/view/trust/ProductsPage.vue'),
    props: true,
  },
];
