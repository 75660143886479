import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import axiosSetup from './config/dataService/dataService';
import './static/css/style.scss';
import { i18n } from './config/i18n';
import ActionCableVue from 'actioncable-vue';
// Vue 3rd party plugins
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';

import '@/core/plugins/fonts';
import '@/core/plugins/ant-design';
import '@/core/components/custom';
import '@/core/components/style';

//if (process.env.NODE_ENV == 'development') {
//  const { worker } = require('./mocks/browser');
//  worker.start();
//}

app.config.productionTip = false;
app.use(store);
app.use(i18n);

app.use(router);
app.use(Vue3Lottie);

app.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  connectImmediately: false,
});

axiosSetup();

app.mount('#app');
