const routes = [
  {
    path: 'login/:referralCode?',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
  },
  {
    path: 'register/:referralCode?',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Signup.vue'),
  },

  {
    path: 'registration-success/',
    name: 'register-success',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/RegistrationSuccessfull.vue'),
  },
  {
    path: 'forgot-password/:referralCode?',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  },
  {
    path: 'password/:confirmationCode',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ResetPassword.vue'),
  },
  {
    path: 'email/:confirmationCode',
    name: 'mailAccept',
    component: () => import(/* webpackChunkName: "mailAccept" */ '@/view/authentication/Mail.vue'),
    props: true,
  },
  {
    path: 'resend-code/:referralCode?',
    name: 'resendCode',
    component: () => import(/* webpackChunkName: "mailResend" */ '@/view/authentication/ForgotPassword.vue'),
    props: true,
  },
];

export default routes;
