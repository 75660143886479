export default {
  profilePersonalDataBegin(state) {
    state.creatingText = null;
    state.isLoading = true;
    state.error = null;
  },

  profilePersonalDataSuccess(state, data) {
    state.isLoading = false;
    state.data = data.data.attributes;
    state.userData = data.data.attributes.user_personal_data;
    state.subscription = data.data.attributes.subscription;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  profileWalletsDataSuccess(state, data) {
    state.isLoading = false;
    state.wallets.data = data.data;
    state.wallets.meta = data.meta.pagination;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  getAllWalletsDataSuccess(state, data) {
    state.isLoading = false;
    state.wallets.data = data.data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosCreateWalletSuccess(state, data) {
    state.creatingText = data.statusText;
    state.wallets.data.unshift(data.data.data);

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosDeltetWalletSuccess(state, data) {
    state.splice(data, 1);
  },

  profilePersonalDataErr(state, err) {
    setTimeout(() => {
      state.isLoading = false;
    }, 500);
    state.error = err;
  },

  updateWalletsSuccess(state, data) {
    if (state.wallets.data) {
      state.wallets.data.forEach((e) => {
        if (e.id == data.id) {
          e.attributes.status = data.status;
        }
      });
    }
  },

  axiosDataChange(state, { data }) {
    state.userData = Object.assign(state.userData, data);

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  profileChangePersonalDataSuccess(state) {
    state.error = null;
    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosDataContactsChange(state, data) {
    state.data.phone = data.phone;
  },

  axiosChangeSuccess(state, data) {
    state.changeSuccess = data;
    state.error = null;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosChangeErr(state, data) {
    state.error = data.response.data;
    state.changeSuccess = data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosGetSuccess(state, data) {
    state.userSettings = data.data.attributes;
    state.locale = state.userSettings.locale;
    state.twoFac = data.data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosGetErr(state, data) {
    state.error = data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosEnableTwoFac(state, data) {
    state.twoFactorKey = data.otp;
    state.error = null;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  getCodeTwoFactory(state, data) {
    state.twoCode = data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  axiosDisableTwoFac(state, data) {
    state.changeSuccess = data;
    state.twoFactorKey = 'messages.settingsDisable2fac';
    state.error = null;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },

  mailAccepted(state, data) {
    state.mailAcc = data;

    setTimeout(() => {
      state.isLoading = false;
    }, 500);
  },
};
