import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  data: [],
  userPartnerInfo: null,
  partnersData: [],
  pagination: null,
  error: null,
  loading: false,
});
function recursion(parent) {
  const child = [];
  parent.children.map(function (element) {
    element.attributes.childLevel = parent.childLevel + 1;
    element.attributes.level = parent.level + 1;
    element.attributes.isFullData = false;
    element.attributes.key = element.id;
    element.attributes.clicked = false;
    element.attributes.expand = false;
    const children = element.attributes.children ? recursion(element.attributes) : null;
    element.attributes.children = children;
    child.push(element.attributes);
  });
  return child;
}

const actions = {
  async axiosPartnersGetData({ commit }) {
    try {
      commit('axiosPartnersReadBegin');
      const query = await axios.get(apiLinks.partners + apiLinks.partner_tree);
      const partnersData = query.data.data.attributes;
      const tableData = [];
      partnersData.partners.data.map(function (element) {
        element.attributes.childLevel = 1;
        element.attributes.level = query.data.data.attributes.level;
        element.attributes.expand = false;
        element.attributes.isFullData = false;
        element.attributes.key = element.id;

        const children = element.attributes.children ? recursion(element.attributes) : null;
        element.attributes.children = children;
        if (!element.attributes.branch) {
          element.attributes.branch = 14;
        }
        tableData.splice(element.attributes.branch - 1, 0, element.attributes);
      });
      const userParams = { code: partnersData.code, level: partnersData.level };
      await commit('axiosPartnersReadSuccess', { tableData, userParams });
    } catch (err) {
      await commit('axiosPartnersReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
