import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  notifications: null,
  tosterNotifications: null,
  notification: null,
  pagination: [],
  error: null,
  newNotifications: 0,
});

const actions = {
  async getAllNotifications({ commit }, data) {
    const { pageNum, pageSize, sortableLink } = data;
    try {
      const query = await axios.get(
        apiLinks.notifications_url + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('getNotificationsSuccess', query.data);
    } catch (err) {
      commit('axiosSendErr', err);
    }
  },

  async getAllTosterNotifications({ commit }, perPage) {
    try {
      const query = await axios.get(
        apiLinks.notifications_url + `?page=1&per_page=${perPage}&sort_by=read_at&order=desc`,
      );

      commit('getTosterNotificationsSuccess', query.data);
    } catch (err) {
      commit('axiosSendErr', err);
    }
  },
  getNewDataNotification({ commit }, data) {
    commit('updateNotifications', data);
  },

  async readNotification({ commit }, { id, idx }) {
    const query = await axios.post(apiLinks.notifications_url + `${id}/` + apiLinks.read);
    commit('axiosReadSuccess', { data: query.data, idx });
  },

  async readAllNotification({ commit }) {
    const query = await axios.post(apiLinks.notifications_url + apiLinks.read);
    commit('axiosReadSuccess', { data: query.data });
  },

  async getNotification({ commit }, id) {
    try {
      const query = await axios.get(apiLinks.notifications_url + id);

      commit('getNotificationSuccess', query.data);
    } catch (err) {
      commit('axiosSendErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
