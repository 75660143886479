export default {
  tradingAccountsReadBegin(state, data) {
    if (data && !typeof data?.getTrade) {
      state.createLoading = true;
    } else {
      state.loading = true;
    }
    if (data?.getTrade) {
      state.data = [];
    }
  },

  tradingAccountsReadSuccess(state, data) {
    state.data = data.data;
    state.meta = data.meta.pagination;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  tradingAccountReadSuccess(state, data) {
    state.accountData = data.data;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  axiosTradingAccountsReadErr(state, err) {
    setTimeout(() => {
      state.loading = false;
      state.createLoading = false;
    }, 500);
    if (err.response?.status != 500) {
      state.error = err.response?.data;
    } else {
      state.error = 500;
    }
  },

  leveragesReadSuccess(state, data) {
    state.leverages = data;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  tradingAccountCreateSuccess(state, data) {
    state.error = null;
    if (
      state.data[0] &&
      state.data[0]?.attributes?.mt5_account_data?.kind == data.data.attributes.mt5_account_data.kind
    ) {
      state.data = [data.data, ...state.data];
    }
    state.createLoading = false;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  tradingAccountTopUpSuccess(state) {
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  axiosDealsGetSuccess(state, data) {
    state.dealsData = data.data;
    state.dealsMeta = data.meta.pagination;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  clearTradingErrors(state) {
    state.error = null;
  },

  axiosAccountGraphStats(state, data) {
    state.graphData = data;
    state.loading = false;
  },

  updateRequestDeposit(state, data) {
    state.data = state.data.map((e) => {
      if (e.id == data.object?.account_id || e.id == data.account_id) {
        e.attributes.amount += data.object.amount / 100;
        e.attributes.mt5_account_data.status = 'active';
      }
      return e;
    });
  },
  closeAccount(state, data) {
    state.data.find((e) => {
      if (e.id == data.id) {
        e.attributes.mt5_account_data.status = 'closed';
      }
      return e.id == data.id;
    });

    let updateAccount = state.data.find((e) => e.id == data.id);
    if (updateAccount) {
      updateAccount.attributes.mt5_account_data.status = 'closed';
    }
  },

  checkContestMember(state, data) {
    // Таблица
    let updateAccount = state.data.find((e) => e.id == data.id);
    if (updateAccount) {
      updateAccount.attributes = { ...updateAccount.attributes, ...data.attributes };
    }
    //Страница
    if (state.accountData.id == data.id) {
      state.accountData.attributes = { ...state.accountData.attributes, ...data.attributes };
    }
  },

  updateMt5AccountData(state, data) {
    if (state.accountData.id == data.account_id) {
      state.accountData.attributes = { ...state.accountData.attributes, ...data };
    }
    let updateAccount = state.data.find((e) => e.id == data.id);
    if (updateAccount) {
      updateAccount.attributes = { ...updateAccount.attributes, ...data };
    }
  },
};
