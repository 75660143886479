export default {
  lootBoxesBegin(state) {
    state.loading = true;
    state.openedBoxData = {};
  },

  lootBoxesReadSuccess(state, { opened, closed }) {
    state.loading = false;
    state.openedData = opened.data.data;
    state.closedData = closed.data.data;
  },

  openLootboxSuccess(state, data) {
    state.openedBoxData = null;
    state.openedBoxData = data;
    state.error = null;

    setTimeout(() => {
      state.loading = false;
    }, 2000);
  },

  setNewOpenLootBox(state, data) {
    data.id = state.usersOpen[0] ? state.usersOpen[0].id + 1 : 0;
    state.usersOpen = [data, ...state.usersOpen];

    if (state.usersOpen.length > 10) {
      state.usersOpen = state.usersOpen.filter((el, idx) => idx < 10);
    }
  },

  updateLootBox(state, data) {
    data.attributes = {
      status: data.status,
      prize_type: data.prize_type,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };
    if (data.status == 'created') {
      state.closedData = [data, ...state.closedData];
    } else {
      // удаляем элемент с массива не открытых лутбоксов, если он есть
      state.closedData = state.closedData.filter((element) => element.id != data.id);

      // ищем элемент в массива открытых боксов
      let finded = state.openedData.find((e) => e.id == data.id);
      // если нашли, то поменяли данные
      if (finded && finded !== -1) {
        finded = data;
        return;
      }

      //  не нашли  => добавили
      state.openedData = [data, ...state.openedData];
    }
  },
};
