import app from '../../config/configApp';
import {
  Button,
  Menu,
  Dropdown,
  Select,
  Skeleton,
  Spin,
  Statistic,
  InputNumber,
  Form,
  Pagination,
  Tooltip,
  Radio,
  Carousel,
  Switch,
  Avatar,
  Row,
  Col,
  Input,
  Popover,
  Badge,
  Table,
  message,
  Tabs,
  Alert,
  PageHeader,
  DatePicker,
  Progress,
  Collapse,
  Tag,
} from 'ant-design-vue';

// app.prototype.$message = message;
app.config.globalProperties.$message = message;

app.use(Alert);
app.use(Button);
app.use(Menu);
app.use(Switch);
app.use(Row);
app.use(Col);
app.use(Input);
app.use(InputNumber);
app.use(Popover);
app.use(Avatar);
app.use(Dropdown);
app.use(Badge);
app.use(Skeleton);
app.use(Spin);
app.use(Statistic);
app.use(Carousel);
app.use(Radio);
app.use(Tooltip);
app.use(Form);
app.use(Select);
app.use(Table);
app.use(Pagination);
app.use(PageHeader);
app.use(DatePicker);
app.use(Progress);
app.use(Tabs);
app.use(Collapse);
app.use(Tag);
