import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  option: {
    data: [],
    meta: {},
  },
  purchasesData: [],
  purchasesMeta: [],
  tasksData: [],
  offersData: [],
  usersOpen: [],
  offersOptionsData: [],
  openedBoxData: {},
  loading: false,
  url: null,
  pagination: null,
  error: null,
});

const actions = {
  async getOffersOptions({ commit }) {
    try {
      commit('shopBegin');
      const query = await axios.get(`${apiLinks.shop_offers}/options`);
      commit('offersOptionsReadSuccess', query.data);
    } catch (err) {
      commit('shopErr', err);
    }
  },
  async getPurchases({ commit }, { page, per_page, kind }) {
    try {
      commit('shopBegin');
      let query = await axios.get(
        `${apiLinks.shop_purchases}?page=${page}&per_page=${
          per_page ? per_page : 3
        }&sort_by=created_at&order=desc&kind=${kind}`,
      );
      commit('purchasesGetSuccess', { data: query.data, kind });
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async axiosOffersGetData({ commit }) {
    try {
      commit('shopBegin');
      const query = await axios.get(`${apiLinks.shop_offers}`);
      commit('offersReadSuccess', query.data);
    } catch (err) {
      commit('shopErr', err);
    }
  },

  async axiosPurchasesGetData({ commit }) {
    try {
      commit('shopBegin');
      const query = await axios.get(`${apiLinks.shop_purchases}`);
      commit('purchasesReadSuccess', query.data);
    } catch (err) {
      commit('shopErr', err);
    }
  },

  async axiosTasksGetData({ commit }) {
    try {
      commit('shopBegin');
      const query = await axios.get(`${apiLinks.marketing}`);
      commit('marketingReadSuccess', query.data);
    } catch (err) {
      commit('shopErr', err);
    }
  },

  async purchaseOptionInShop({ commit }, { amount, kind, item_type }) {
    const createbleEntity = {
      amount: +amount,
      kind: +kind,
      item_type: item_type,
    };

    try {
      commit('shopBegin');
      await axios.post(`${apiLinks.shop_purchases}`, createbleEntity);

      commit('purchaseOptionInShopSuccess');
    } catch (err) {
      commit('purchaseInShopErr', err);
    }
  },

  async purchaseSubscriptionInShop({ commit }, { account_id, kind }) {
    const createbleEntity = {
      account_id: +account_id,
      kind: +kind,
    };

    try {
      commit('shopBegin');
      await axios.post(`${apiLinks.shop_purchases}`, createbleEntity);
      commit('purchaseOptionInShopSuccess');
    } catch (err) {
      commit('purchaseInShopErr', err);
    }
  },

  async purchaseInShop({ commit }, { kind }) {
    try {
      commit('shopBegin');
      await axios.post(`${apiLinks.shop_purchases}`, { kind });

      commit('purchaseInShopSuccess', { kind });
    } catch (err) {
      commit('purchaseInShopErr', err);
    }
  },

  async openShop({ commit, state }) {
    try {
      commit('shopBegin');
      const openBox = state.closedData[state.closedData.length - 1];

      const query = await axios.patch(apiLinks.loot_boxes + openBox.id + '/open');

      commit('openShopSuccess', query.data.data);
    } catch (err) {
      commit('shopErr', err);
    }
  },

  async updateShop({ commit }, data) {
    commit('updateShop', data);
  },

  setNewOpenShop({ commit }, data) {
    commit('setNewOpenShop', data);
  },
  clearShopErrors({ commit }) {
    commit('clearShopErrors');
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  state,
};
