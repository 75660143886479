import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  data: null,
  sessionData: null,
  url: null,
  pagination: null,
  fileLoading: false,
  error: null,
});

const actions = {
  async axiosSecurityGetData({ commit }, { pageNum, pageSize, sortableLink = '?sort_by=id&order=desc' }) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.security + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`);
      commit('axiosReadSuccess', query.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async axiosSecurityGetSession({ commit }, id) {
    try {
      commit('axiosReadBegin');
      const query = await axios.get(apiLinks.security + id);
      commit('axiosReadSessionSuccess', query.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  axiosSecurityCloseSession({ commit }, id) {
    try {
      axios.delete(apiLinks.security + id);
      commit('closeSessionSuccess', id);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async axiosSecurityCloseAllSessions({ commit }) {
    try {
      axios.delete(apiLinks.security + apiLinks.delete_all);
    } catch (err) {
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
