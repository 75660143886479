import axios from 'axios';
import mutations from './mutations';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  isLoading: false,
  error: null,
  products: [],
  data: [],
  meta: {},
});

const actions = {
  async trustProductsGetData({ commit }) {
    try {
      commit('trustDataBegin');
      const query = await axios.get(apiLinks.trust_products);

      commit('trustProductsDataSuccess', query.data);
    } catch (err) {
      await commit('trustDataErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
