export default {
  contestsReadBegin(state) {
    state.loading = true;
  },
  contestsReadSuccess(state, data) {
    state.loading = false;
    state.data = data.data;
    state.pagination = data.meta;
  },
  getOneContestSuccess(state, data) {
    state.loading = false;
    state.contestData = data.data;
    state.error = null;
  },
  membersReadSuccess(state, data) {
    state.loading = false;
    state.membersData = data.data;
    state.membersMeta = data.meta;
    state.error = null;
  },
  perticipateReadBegin(state) {
    state.loadingPerticipate = true;
    state.error = null;
  },
  perticipateSuccess(state) {
    state.loadingPerticipate = false;
    state.error = null;
  },
  axiosReadErr(state, err) {
    state.loading = false;
    state.loadingPerticipate = false;
    state.error = err.response.data;
  },

  axiosUpdateBegin(state) {
    state.loading = true;
  },

  axiosUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  closeSessionSuccess(state, id) {
    state.data = state.data.filter((e) => e.id != id);
  },

  axiosSingleDataBegin(state) {
    state.loading = true;
  },

  resetContestErrors(state) {
    state.error = null;
  },

  updateUser(state) {
    state.data = state.data.map((e) => {
      e.attributes.attempts_count = 1;
      return e;
    });
  },
};
