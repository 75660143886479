import axios from 'axios';
import { getItem } from '@/utility/localStorageControl';

import store from '../../vuex/store';
import router from '../../routes/protectedRoute';

export default function axiosSetup() {
  axios.interceptors.request.use(
    async (config) => {
      const user = getItem('user');
      if (user) {
        config.headers = {
          Authorization: user.token,
          Accept: 'application/json',
        };
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (response) => response,
    async function (error) {
      const originalRequest = error.config;
      if (error?.response.status === 401 && originalRequest.url.includes('auth/refresh')) {
        store.dispatch('auth/logout', 'expireToken');
        router.push('/auth/login');
        return Promise.reject(error);
      } else if (
        error?.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes('auth/login') &&
        !originalRequest.url.includes('auth/otp')
      ) {
        originalRequest._retry = true;
        const user = await store.dispatch('auth/refresh');
        axios.defaults.headers.common['Authorization'] = user.token;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    },
  );
}
