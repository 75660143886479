<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }"
  >
    <Suspense>
      <template #default>
        <router-view></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from 'vue3-styled-components';
import { theme } from './config/theme/themeVariables';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    ThemeProvider,
  },
  setup() {
    const { state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const isLoading = computed(() => state.themeLayout.loading);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);

    onMounted(() => {
      if (process.env.NODE_ENV === 'production') {
        let script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-9EHHN9S39Y`;
        script.async = true;
        document.querySelector('body').append(script);

        script = document.createElement('script');
        script.innerHTML = ` window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'G-9EHHN9S39Y');`;

        document.querySelector('body').append(script);
      }
    });

    return {
      theme,
      rtl,
      darkMode,
      topMenu,
      isLoading,
    };
  },
};
</script>
