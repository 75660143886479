import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  openedData: [],
  closedData: [],
  usersOpen: [],
  openedBoxData: {},
  url: null,
  pagination: null,
  fileLoading: false,
  error: null,
  loadingPerticipate: null,
});

const actions = {
  async axiosLootBoxesGetData({ commit }) {
    try {
      commit('lootBoxesBegin');
      const opened = await axios.get(`${apiLinks.loot_boxes}?status[]=opened&order=desc`);
      const closed = await axios.get(`${apiLinks.loot_boxes}?status[]=created`);
      commit('lootBoxesReadSuccess', { opened, closed });
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async openLootbox({ commit, state }) {
    try {
      commit('lootBoxesBegin');
      const openBox = state.closedData[state.closedData.length - 1];

      const query = await axios.patch(apiLinks.loot_boxes + openBox.id + '/open');

      commit('openLootboxSuccess', query.data.data);
    } catch (err) {
      commit('axiosReadErr', err);
    }
  },

  async updateLootBox({ commit }, data) {
    commit('updateLootBox', data);
  },

  setNewOpenLootBox({ commit }, data) {
    commit('setNewOpenLootBox', data);
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  state,
};
