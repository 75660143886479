/* eslint-disable */
import axios from 'axios';
import { getItem, setItem, removeItem } from '@/utility/localStorageControl';
import router from '../../routes/protectedRoute';
import apiLinks from '@/vuex/api.js';

class AuthService {
  login({ email, password, fingerprint, locale }) {
    return axios
      .post(apiLinks.login, {
        email,
        password,
        fingerprint,
        locale,
      })
      .then((response) => {
        if (response.data.token) {
          setItem('user', response.data);
        }
        return response.data;
      });
  }
  refresh(token, fingerprint) {
    return axios
      .post(apiLinks.refresh, {
        refresh: token,
        fingerprint,
      })
      .then((response) => {
        if (response.data.token) {
          setItem('user', response.data);
        }
        return response.data;
      });
  }
  logout() {
    const user = getItem('user');
    const updatableEntity = {
      refresh: user.refresh,
    };
    return axios.post(apiLinks.logout, updatableEntity).then(
      setTimeout(() => {
        removeItem('user');
        router.push('/auth/login');
      }),
    );
  }
  authSecond(user) {
    return axios
      .post(apiLinks.second_auth, {
        email: user.email,
        password: user.password,
        otp_attempt: user.code,
      })
      .then((response) => {
        if (response.data.token) {
          setItem('user', response.data);
        }
        return response.data;
      });
  }
  forgotPassword(user) {
    return axios
      .post(apiLinks.reset_password, {
        user: {
          email: user.email,
          locale: user.locale,
        },
      })
      .then((response) => {
        return response;
      });
  }
  resetPassword({ reset_password_token, password_confirmation, password, locale }) {
    return axios
      .put(apiLinks.reset_password + reset_password_token, {
        user: { password_confirmation, password, reset_password_token, locale },
      })
      .then((response) => {
        return response;
      });
  }

  register({ email, password, password_confirmation, referral_code, locale }) {
    return axios.post(apiLinks.auth_url, {
      client: {
        email,
        password,
        password_confirmation,
        referral_code,
        locale,
      },
    });
  }
}

export default new AuthService();
