import store from '@/vuex/store';

export default {
  shopBegin(state) {
    state.loading = true;
    state.error = null;
  },

  purchasesGetSuccess(state, { data, kind }) {
    if (kind.includes('option')) {
      // state.option.data = [...state.option.data, ...data.data];
      state.option.data = data.data;
      state.option.meta = data.meta.pagination;
    }

    setTimeout(() => {
      state.loading = false;
    }, 100);
  },

  purchasesReadSuccess(state, { data, meta }) {
    state.loading = false;
    state.purchasesData = data;
    state.purchasesMeta = meta.pagination;
  },

  offersReadSuccess(state, data) {
    state.loading = false;
    state.offersData = data;
  },

  offersOptionsReadSuccess(state, data) {
    state.loading = false;
    state.offersOptionsData = data;
  },

  marketingReadSuccess(state, data) {
    const marketing = {
      once: [],
      multy: [],
      completed: [],
    };

    data.points_data.forEach((task) => {
      if (task.completed) {
        marketing.completed = [...marketing.completed, task];
        return;
      }
      marketing[task.type] = [...marketing[task.type], task];
    });

    state.tasksData = [...marketing.once, ...marketing.multy, ...marketing.completed];

    state.loading = false;
  },

  purchaseOptionInShopSuccess(state) {
    state.error = null;
    state.loading = false;
  },

  purchaseInShopSuccess(state, { kind }) {
    state.error = null;
    state.loading = false;
    // уменьшение поинтов за покупку
    store.dispatch('pointUpdateAmount', state.offersData[kind]?.price);
  },

  purchaseInShopErr(state, err) {
    if (err.response) {
      state.error = err.response.data;
    } else {
      state.error = err.data;
    }
    state.loading = false;
  },

  shopErr(state, err) {
    state.error = err.response.data;
  },

  clearShopErrors(state) {
    state.error = null;
  },
};
