import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  public: {
    data: [],
    meta: {},
  },
  invest: {
    data: [],
    meta: {},
    total: {},
  },
  profit: {
    data: [],
    meta: {},
  },
  transactions: {
    data: [],
    meta: {},
  },
  graphData: [],
  account: null,
  loading: null,
  loadingTable: false,
  error: null,
});
const actions = {
  async getPublicAccounts({ commit }, { page, per_page, type, restart }) {
    try {
      commit('accountsReadBegin', restart);
      let query = await axios.get(
        `${apiLinks[type]}?page=${page}&per_page=${per_page ? per_page : 3}${
          type.includes('published') ? '&sort_by=profit_all&order=desc' : '&sort_by=created_at&order=desc'
        }`,
      );
      commit('publicGetSuccess', { data: query.data, type });
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async getPublicAccount({ commit }, { id, type }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.get(`${apiLinks[type]}${id}`);
      commit('publicAccountReadSuccess', query.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async publishAccount({ commit }, { id, data }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.patch(`${apiLinks.trading_real}${id}/${apiLinks.publish}`, { ...data });
      commit('updateTradingAccount', query.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async unpublishAccount({ commit }, { id }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.patch(`${apiLinks.trading_real}${id}/${apiLinks.unpublish}`);
      commit('unpublishAccount', query.data.data.id);
      commit('updateTradingAccount', query.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async createInvestorAccount({ commit }, { name, id, amount }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.post(
        `${apiLinks.investor_accounts}?name=${name}&published_account_id=${id}&amount=${amount}`,
      );
      commit('createInvestorAccountSuccess', query.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async getGraphPublicStats({ commit }, { id, type }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.get(`${apiLinks[type]}${id}/${apiLinks.graph}?group_by=hour`);
      await commit('axiosAccountGraphStats', query.data);
    } catch (err) {
      await commit('axiosError', err);
    }
  },

  async sendPublicTransfer({ commit }, { amount, account_from_id, account_to_id }) {
    const createbleEntity = {
      amount: +amount,
      account_from_id: +account_from_id,
      account_to_id: +account_to_id,
    };
    try {
      commit('accountsReadBegin');
      const query = await axios.post(apiLinks.accounts + apiLinks.transfer_requests, createbleEntity);
      commit('transferSendSuccess', query.data);
    } catch (err) {
      await commit('axiosError', err);
    }
  },

  async closeInvestorAccount({ commit }, { id }) {
    try {
      commit('accountsReadBegin');
      const query = await axios.patch(`${apiLinks.investor_accounts}${id}/${apiLinks.close}`);
      commit('closeAccount', query.data.data.id);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  async getProfit({ commit }, { id, group_by, type }) {
    try {
      commit('tableReadBegin');
      const query = await axios.get(`${apiLinks[type]}${id}/${apiLinks.profit}?group_by=${group_by}`);
      commit('getProfitSuccess', query.data);
    } catch (err) {
      commit('axiosError', err);
    }
  },

  clearPublicErrors({ commit }) {
    commit('clearPublicErrors');
  },

  createInvestorAccountSuccess({ commit }) {
    commit('clearPublicErrors');
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  state,
};
