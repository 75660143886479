export default {
  axiosReadBegin(state) {
    state.loading = true;
  },
  axiosReadSuccess(state, data) {
    state.loading = false;
    state.data = data.data;
    state.pagination = data.meta;
  },
  axiosReadSessionSuccess(state, data) {
    state.loading = false;
    state.sessionData = data.data;
  },
  axiosReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosUpdateBegin(state) {
    state.loading = true;
  },

  axiosUpdateSuccess(state) {
    state.loading = false;
    // state.data = data;
  },

  axiosUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  closeSessionSuccess(state, id) {
    state.data = state.data.filter((e) => e.id != id);
  },
  axiosSingleDataBegin(state) {
    state.loading = true;
  },
};
