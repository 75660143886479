export default {
  axiosPartnersReadBegin(state) {
    state.loading = false;
  },

  axiosPartnersReadSuccess(state, { tableData, userParams }) {
    state.partnersData = tableData;
    state.userPartnerInfo = userParams;
    state.loading = true;
  },

  axiosPartnersReadErr(state, err) {
    state.loading = true;
    state.error = err;
  },
};
