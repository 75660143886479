const url = process.env.VUE_APP_API_URL + 'client/';
const auth_url = process.env.VUE_APP_API_URL + 'auth/';

const notifications_url = process.env.VUE_APP_API_URL + 'notifications/';

//links models
const links_accounts = 'accounts/';
const links_profile = 'profile/';
const links_login = 'login/';
const links_logout = 'logout/';
const links_otp = 'otp/';
const links_security = 'security/';
const links_refresh = 'refresh/';
const links_partners = 'partners/';
const links_wallets = 'wallets/';
const links_dashboard = 'dashboard/';
const links_stats = 'stats/';
const links_graph_stats = 'graph_stats';
const links_due_date = 'due_date';
const links_password = 'password/';
const links_email = 'email/';
const links_phone = 'phone/';
const links_exchange_rates = 'exchange_rates/';
const links_trade = 'trade/';
const links_request_exchanges = 'request_exchanges/';
const links_contests = 'contests/';
const links_loot_boxes = 'loot_boxes/';
const links_shop = 'shop/';
const links_offers = 'offers/';
const links_purchases = 'purchases/';
const links_marketing = 'marketing/';
const links_published_accounts = 'published_accounts/';
const links_investor_accounts = 'investor_accounts/';
const links_trust = 'trust/';
//endpoints
const personal_data = 'personal_data/';
const personal_user_data = 'user_personal_data/';
const requests_kinds = 'request_kinds/';
const delete_all = 'delete_all/';
const read = 'read/';
const partner_tree = 'partner_tree/';
const payment_gates = 'payment_gates/';
const transactions = 'transactions/';
const cancel_request = 'cancel_request/';
const show = 'show/';
const notifications = 'notifications/';
const deposit_requests = 'request_deposits/';
const withdrawal_requests = 'request_withdrawals/';
const transfer_requests = 'request_transfers/';
const settings = 'settings/';
const two_fac = 'two_fac/';
const child_tree = 'child_tree/';
const demo_accounts = 'demo_accounts/';
const real_accounts = 'real_accounts/';
const leverages = 'leverages/';
const rates = 'rates/';
const deals = 'deals/';
const graph = 'graph';
const members = 'members/';
const publish = 'publish/';
const unpublish = 'unpublish/';
const close = 'close/';
const profit = 'profit/';
const check = 'check/';
const products = 'products/';

//links
const accounts = url + links_accounts;
const profile = url + links_profile;
const dashboard = url + links_dashboard;
const partners = url + links_partners;
const stats = partners + links_stats;
const graph_stats = partners + links_graph_stats;
const security = url + links_profile + links_security;
const login = auth_url + links_login;
const email = auth_url + links_email;
const refresh = auth_url + links_refresh;
const logout = auth_url + links_logout;
const second_auth = auth_url + links_otp;
const reset_password = auth_url + links_password;
const wallets = url + links_wallets;
const due_date = url + links_due_date;
const exchange_rates = url + links_exchange_rates;
const request_exchanges = accounts + links_request_exchanges;

const trading = url + links_trade + links_accounts;
const trading_demo = url + links_trade + demo_accounts;
const trading_real = url + links_trade + real_accounts;
const trading_leverages = url + links_trade + leverages;
const trading_accounts = url + links_trade + links_accounts;
const contests = url + links_trade + links_contests;
const loot_boxes = url + links_loot_boxes;
const published_accounts = url + links_trade + links_published_accounts;
const investor_accounts = url + links_trade + links_investor_accounts;

const member = url + links_trade + members;

const shop_offers = url + links_shop + links_offers;
const shop_purchases = url + links_shop + links_purchases;
const marketing = url + links_marketing;
const all_transactions = url + transactions;

const trust_products = url + links_trust + products;

export default {
  accounts,
  profile,
  dashboard,
  partners,
  stats,
  graph_stats,
  security,
  login,
  refresh,
  logout,
  second_auth,
  reset_password,
  wallets,
  due_date,
  personal_data,
  personal_user_data,
  email,
  requests_kinds,
  delete_all,
  read,
  partner_tree,
  exchange_rates,
  request_exchanges,
  rates,
  payment_gates,
  transactions,
  all_transactions,
  cancel_request,
  show,
  notifications,
  deposit_requests,
  withdrawal_requests,
  transfer_requests,
  settings,
  two_fac,
  close,
  child_tree,
  notifications_url,
  auth_url,
  links_password,
  links_email,
  deals,
  trading,
  trading_demo,
  trading_real,
  trading_leverages,
  trading_accounts,
  published_accounts,
  investor_accounts,
  publish,
  unpublish,
  links_phone,
  graph,
  contests,
  members,
  member,
  loot_boxes,
  shop_offers,
  shop_purchases,
  marketing,
  profit,
  check,
  trust_products,
};
