import FingerprintJS from '@fingerprintjs/fingerprintjs';
const finger = async () => {
  try {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    return result.visitorId;
  } catch (e) {
    console.log(e);
  }
};
export default finger;
