export default {
  axiosAccountsReadBegin(state) {
    state.loading = true;
  },

  axiosAccountsReadSuccess(state, data) {
    state.data = data.accounts;
    state.mainAccount = data.mainAccount;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  accountReadSuccess(state, data) {
    state.accountData = data.data.attributes;
    state.loading = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },
  getWithdrawalDueDateSuccess(state, data) {
    state.withdrawalDueDate = data.date_complete;
  },
  axiosTransactionsGetSuccess(state, data) {
    state.transactionsData = data.data;
    state.transactionsMeta = data.meta.pagination;
    state.loading = false;
  },
  axiosTransferCancelSuccess(state, { idx, page }) {
    if (state.dashboardData && page != 'account') {
      state.dashboardData.active_to_personal.data.splice(idx, 1);
    }
  },
  axiosAccountsReadErr(state, err) {
    setTimeout(() => {
      state.loading = false;
    }, 500);
    if (err.response?.status != 500) {
      state.error = err.response?.data;
    } else {
      state.error = 500;
    }
    state.cryptoLoading = false;
  },

  axiosTransfersGetSuccess(state, data) {
    state.transfersData = data.data;
    state.transfersMeta = data.meta.pagination;
    setTimeout(() => {
      state.loading = false;
      state.fileLoading = false;
    }, 500);
  },

  getAllWithdrawalsSuccess(state, data) {
    state.withdrawalsData = data.data;
    state.withdrawalsMeta = data.meta.pagination;
    setTimeout(() => {
      state.loading = false;
      state.fileLoading = false;
    }, 500);
  },

  axiosWithdrawalSendSuccess(state, data) {
    if (state.withdrawalsData) {
      state.withdrawalsData.unshift(data.data);
    }
    if (state.activeWithdrawals) {
      state.activeWithdrawals.unshift(data.data);
    }
  },

  axiosTransferSendSuccess(state, data) {
    if (state.transfersData) {
      state.transfersData.unshift(data.data);
    }
    if (state.activeInvests && data.data.attributes.account_to_type == 'invest') {
      state.activeInvests.unshift(data.data);
    }
    if (state.activeToPersonal && data.data.attributes.account_to_type == 'personal') {
      state.activeToPersonal.unshift(data.data);
    }
  },

  transferUpdateSuccess(state, data) {
    if (state.transfersData) {
      state.transfersData.map((e) => {
        if (e.id == data.id) {
          e.attributes.status = data.status;
        }
      });
    }

    if (state.activeInvests) {
      state.activeInvests = state.activeInvests.filter((val) => val.id != data.id);
    }

    if (state.activeToPersonal) {
      state.activeToPersonal = state.activeToPersonal.filter((val) => val.id != data.id);
    }
  },
  updateDepositPointSuccess(state, data) {
    state.data[3].attributes.amount = +state.data[3].attributes.amount + data.amount_subunit / 100;
  },
  updateRequestWithdrawalSuccess(state, data) {
    if (state.withdrawalsData) {
      state.withdrawalsData.map((e) => {
        if (e.id == data.id) {
          e.attributes.status = data.status;
        }
      });
    }

    if (state.activeWithdrawals) {
      state.activeWithdrawals = state.activeWithdrawals.filter((val) => val.id != data.id);
    }
  },

  updateRequestExchangeSuccess(state, data) {
    console.log(data);
  },

  axiosAccountsSendBegin(state) {
    state.fileLoading = true;
    state.coinPaymentInfo = null;
    state.error = null;
  },

  getCoinpaymentsInfoSuccess(state, data) {
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
    state.coinPaymentInfo = data.data;
    state.error = false;
  },

  sendDepositSuccess(state, data) {
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
    state.coinPaymentInfo = data.data;
    state.error = false;
  },

  axiosAccountsSendSuccess(state) {
    state.error = false;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },
  startCryptoAmount(state) {
    state.cryptoLoading = true;
  },
  getAmountSuccess(state, data) {
    state.cryptoAmount = data.data;
    state.cryptoLoading = false;
  },

  axiosAccountsSendErr(state, err) {
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
    if (err.response?.status != 500) {
      state.error = err.response?.data;
    } else {
      state.error = 500;
    }
  },

  getAllCoinpaymentsDepositsSuccess(state, data) {
    state.coinPaymentsData = data.data;
    state.paymentsMeta = data.meta.pagination;
    setTimeout(() => {
      state.fileLoading = false;
    }, 500);
  },

  getDashboardDataSuccess(state, data) {
    state.tokenSale = data.data.attributes.token_sale;
    state.activeDeposits = data.data.attributes.coinpayments_trx.data;
    state.activeInvests = data.data.attributes.active_invests.data;
    state.activeWithdrawals = data.data.attributes.withdrawals.data;
    state.activeToPersonal = data.data.attributes.active_to_personal.data;
    state.loading = false;
  },

  getStatsSuccess(state, data) {
    state.loading = false;
    state.stats = data.data.attributes;
  },

  getGraphStatsSuccess(state, data) {
    state.loading = false;
    state.graphStats = data.data;
  },
  axiosAccountsClearData(state) {
    state.accountData = null;
    state.transactionsData = null;
  },

  getMTHCurrencySuccess(state, data) {
    state.MTHCurrency = data;
  },

  axiosBuySendSuccess(state, data) {
    console.log(data);
  },
  clearTradingErrors(state) {
    state.error = null;
  },
  pointUpdateAmount(state, amount) {
    if (state.data[3]) {
      state.data[3].attributes.amount -= amount;
    }
  },
};
