import mutations from './mutations';
import axios from 'axios';
import apiLinks from '@/vuex/api.js';

const state = () => ({
  data: null,
  accountData: null,
  coinPaymentInfo: null,
  coinPaymentsData: null,
  transactionsData: [],
  transactionsMeta: {},
  mainAccount: '',
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  sendMessage: null,
  transfersData: null,
  transfersMeta: null,
  cryptoAmount: null,
  paymentsMeta: null,
  activeDeposits: null,
  activeInvests: null,
  activeWithdrawals: null,
  activeToPersonal: null,
  graphStats: null,
  stats: null,
  withdrawalsData: null,
  withdrawalsMeta: null,
  withdrawalDueDate: null,
  MTHCurrency: 0.05,
  cryptoLoading: false,
  tokenSale: { sale: 0, total: 0 },
});
const actions = {
  async axiosAccountsGetData({ commit }) {
    try {
      commit('axiosAccountsReadBegin');
      const query = await axios.get(apiLinks.accounts + '?sort_by=id&order=asc');

      const mainAccount = query.data.data.find((account) => account.attributes.kind === 'personal');
      const bonusAccount = query.data.data.find((account) => account.attributes.kind === 'partner');
      const tokenAccount = query.data.data.find((account) => account.attributes.kind === 'token');
      const pointAccount = query.data.data.find((account) => account.attributes.kind === 'point');

      let accArr = [mainAccount, bonusAccount, tokenAccount, pointAccount];

      commit('axiosAccountsReadSuccess', { accounts: accArr, mainAccount });
    } catch (err) {
      commit('axiosAccountsReadErr', err);
    }
  },

  async accountGetData({ commit }, id) {
    try {
      commit('axiosAccountsReadBegin');
      const query = await axios.get(apiLinks.accounts + id);
      commit('accountReadSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsReadErr', err);
    }
  },
  async getWithdrawalDueDate({ commit }, due_date) {
    try {
      const query = await axios.get(`${apiLinks.due_date}?days=${due_date}`);
      commit('getWithdrawalDueDateSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsReadErr', err);
    }
  },

  async getCryptoAmount({ commit }, { exchange_currency }) {
    try {
      commit('startCryptoAmount');
      const query = await axios.get(apiLinks.exchange_rates, {
        params: { exchange_currency, amount: 1 },
      });

      commit('getAmountSuccess', query);
    } catch (err) {
      commit('axiosAccountsReadErr', err);
    }
  },

  async getTransfers({ commit }, { accId, pageNum, pageSize, sortableLink }) {
    try {
      commit('axiosAccountsReadBegin');

      const query = await axios.get(
        apiLinks.accounts +
          `${accId}/` +
          apiLinks.transfer_requests +
          `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('axiosTransfersGetSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },
  async getTransactions({ commit }, { pageNum, pageSize, sortableLink, accId }) {
    try {
      commit('axiosAccountsReadBegin');

      const query = await axios.get(
        apiLinks.accounts +
          accId +
          '/' +
          apiLinks.transactions +
          `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('axiosTransactionsGetSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },
  async getCoinpaymentsDepositInfo({ commit }, depositId) {
    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.get(apiLinks.accounts + apiLinks.payment_gates + depositId);

      commit('getCoinpaymentsInfoSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  async getAllCoinpaymentsDeposits({ commit }, { pageNum, pageSize, sortableLink }) {
    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.get(
        apiLinks.accounts + apiLinks.payment_gates + `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('getAllCoinpaymentsDepositsSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  async getDemoDepositsRequests({ commit }, { pageNum, pageSize, sortableLink, accId }) {
    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.get(
        apiLinks.accounts +
          accId +
          '/' +
          apiLinks.deposit_requests +
          `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('getAllCoinpaymentsDepositsSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  async getAllWithdrawals({ commit }, { pageNum, pageSize, sortableLink, accId }) {
    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.get(
        apiLinks.accounts +
          accId +
          '/' +
          apiLinks.withdrawal_requests +
          `?page=${pageNum}&per_page=${pageSize}${sortableLink}`,
      );

      commit('getAllWithdrawalsSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  updateRequestWithdrawal({ commit }, data) {
    commit('updateRequestWithdrawalSuccess', data);
  },

  async getAllActiveTransactions({ commit }) {
    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.get(apiLinks.accounts + apiLinks.transactions + `?per_page=100&sort_by=id&oreder=desc`);

      commit('getAllActiveTransactionsSuccess', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },

  async getDashboard({ commit }) {
    try {
      commit('axiosPartnersReadBegin');

      const query = await axios.get(apiLinks.dashboard);

      commit('getDashboardDataSuccess', query.data);
    } catch (err) {
      commit('axiosPartnersReadErr', err);
    }
  },

  async getGraphStats({ commit }, { period }) {
    try {
      const query = await axios.get(apiLinks.graph_stats + `?period=${period}`);
      commit('getGraphStatsSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  async getStats({ commit }) {
    try {
      const query = await axios.get(apiLinks.stats);
      commit('getStatsSuccess', query.data);
    } catch (err) {
      commit('axiosAccountsSendErr', err);
    }
  },

  async sendDeposit({ commit }, { amount, exchange_currency }) {
    const createbleEntity = {
      amount: +amount,
      exchange_currency,
    };

    try {
      commit('axiosAccountsSendBegin');
      const query = await axios.post(apiLinks.accounts + apiLinks.deposit_requests, createbleEntity);
      commit('sendDepositSuccess', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },

  async sendTransfer({ commit }, { amount, account_from_id, account_to_id }) {
    const createbleEntity = {
      amount: +amount,
      account_from_id: +account_from_id,
      account_to_id: +account_to_id,
    };

    try {
      commit('axiosAccountsSendBegin');

      const query = await axios.post(apiLinks.accounts + apiLinks.transfer_requests, createbleEntity);

      commit('axiosAccountsSendSuccess');
      commit('axiosTransferSendSuccess', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },

  updateRequestTransfer({ commit }, data) {
    commit('transferUpdateSuccess', data);
  },

  updateRequestExchange({ commit }, data) {
    commit('updateRequestExchangeSuccess', data);
  },
  updateDepositPoint({ commit }, data) {
    commit('updateDepositPointSuccess', data);
  },

  async cancelTransfer({ commit }, { idx, id, page }) {
    try {
      commit('axiosAccountsSendBegin');

      await axios.post(apiLinks.accounts + apiLinks.transfer_requests + id + '/' + apiLinks.cancel_request);

      commit('axiosTransferCancelSuccess', { idx, page });
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },
  async sendWithdrawal({ commit }, { wallet_id, amount, account_id, priority }) {
    try {
      const createbleEntity = {
        wallet_id: +wallet_id,
        amount: +amount,
        account_id: +account_id,
        priority: +priority,
        kind: 1,
      };
      commit('axiosAccountsSendBegin');
      const query = await axios.post(
        apiLinks.accounts + account_id + '/' + apiLinks.withdrawal_requests,
        createbleEntity,
      );
      commit('axiosAccountsSendSuccess');
      commit('axiosWithdrawalSendSuccess', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },
  async buyMTHTokens({ commit }, data) {
    try {
      commit('axiosAccountsSendBegin');
      const query = await axios.post(apiLinks.request_exchanges, data);
      commit('axiosBuySendSuccess', query.data);
    } catch (err) {
      await commit('axiosAccountsSendErr', err);
    }
  },

  axiosAccountsClearData({ commit }) {
    commit('axiosAccountsClearData');
  },

  clearAccountErrors({ commit }) {
    commit('clearTradingErrors');
  },

  pointUpdateAmount({ commit }, amount) {
    commit('pointUpdateAmount', amount);
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
