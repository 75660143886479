import app from '../../config/configApp';
import Cards from '../../components/cards/CardsFrame.vue';
import FeatherIcons from '../../components/utilities/featherIcons.vue';
import Alerts from '../../components/alerts/Alerts.vue';
import Button from '../../components/buttons/Buttons.vue';
import { PageHeader } from '../../components/pageHeaders/PageHeaders.vue';
import Heading from '../../components/heading/Heading';
import Dropdown from '../../components/dropdown/Dropdown';
import Modal from '../../components/modals/Modals';
import Popover from '../../components/popup/Popup';

[
  Heading,
  Cards,

  Alerts,
  {
    name: 'Button',
    ...Button,
  },

  { name: 'PageHeader', ...PageHeader },
  Dropdown,
  Popover,
  FeatherIcons,
  Modal,
].map((c) => {
  app.component(`sd${c.name}`, c);
});
